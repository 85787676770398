.project {
  padding: 80px 0;
  background-color: #f0d7be;
  color: rgba(33, 37, 50, 0.75);
}

.project h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}

.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgba(62, 39, 35, 0.5);
  overflow: hidden;
}

.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}

.project .nav.nav-pills .nav-link {
  background-color: transparent;
  padding: 17px 0;
  color: #FFF7F0;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
}

.project .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #6E4F47 -5.91%, #c29c90 111.58%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.project .nav.nav-pills .nav-link.active::before,
.project .nav.nav-pills .nav-link:hover::before {
  width: 100%;
}

.project .nav.nav-pills .nav-link.active {
  border: 1px solid rgba(255, 248, 225, 1);
}

.project .nav.nav-pills .nav-link:hover {
  color: rgba(33, 37, 50, 1);
  background-color: rgba(62, 39, 35, 0.3);
}

.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.proj-imgbx img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.4s ease-in-out;
}

.proj-imgbx::before {
  content: "";
  background: rgba(110, 79, 71, 0.75);
  position: absolute;
  width: 100%;
  height: 100%;
  transition: 0.4s ease-in-out;
  z-index: 1;
}

.proj-imgbx:hover::before {
  opacity: 0;
}

.proj-txtx {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 1;
  width: 100%;
  z-index: 2;
  padding: 10px;
  box-sizing: border-box;
}

.proj-imgbx:hover .proj-txtx {
  opacity: 0;
}

.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
  color: #FFF8E1;
}

.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
  color: #eee4dd;
}

.project-card-link {
  text-decoration: none;
  color: inherit;
  display: block;
}
